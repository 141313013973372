// src/components/AddPersonForm.js

import React, { useState } from 'react';
import { FaTrash } from 'react-icons/fa';

const AddPersonForm = ({ onSubmit, onClose, relationOptions, formTitle, loading }) => {
  const [formData, setFormData] = useState({
    name: '',
    gender: '',
    birthDate: '',
    birthPlace: '',
    isDeceased: false,
    deathDate: '',
    deathPlace: '',
    occupation: '',
    photo: '',
    notes: '',
    relation: relationOptions ? 'Parent' : '',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleClearPhoto = () => {
    setFormData({
      ...formData,
      photo: '',
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({
          ...formData,
          photo: reader.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const formattedData = {
      ...formData,
      birthDate: new Date(formData.birthDate),
      deathDate: formData.deathDate ? new Date(formData.deathDate) : null,
    };

    const personId = `${Date.now()}-${Math.floor(Math.random() * 1000)}`;
    onSubmit({ ...formattedData, personId });
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 p-4 overflow-y-auto">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md max-h-full overflow-y-auto">
        <h2 className="text-2xl font-heading mb-4">{formTitle}</h2>
        <form onSubmit={handleSubmit}>
          {relationOptions && (
            <div className="mb-4">
              <label className="block text-softBrown mb-1" htmlFor="relation">Relation</label>
              <select
                id="relation"
                name="relation"
                value={formData.relation}
                onChange={handleChange}
                className="w-full border border-softBrown rounded px-2 py-1"
              >
                {relationOptions.map((option) => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
            </div>
          )}
          <div className="mb-4">
            <label className="block text-softBrown mb-1" htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full border border-softBrown rounded px-2 py-1"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-softBrown mb-1" htmlFor="gender">Gender</label>
            <select
              id="gender"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              className="w-full border border-softBrown rounded px-2 py-1"
              required
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-softBrown mb-1" htmlFor="birthDate">Birth Date</label>
            <input
              type="date"
              id="birthDate"
              name="birthDate"
              value={formData.birthDate}
              onChange={handleChange}
              className="w-full border border-softBrown rounded px-2 py-1"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-softBrown mb-1" htmlFor="birthPlace">Birth Place</label>
            <input
              type="text"
              id="birthPlace"
              name="birthPlace"
              value={formData.birthPlace}
              onChange={handleChange}
              className="w-full border border-softBrown rounded px-2 py-1"
            />
          </div>
          <div className="mb-4 flex items-center">
            <label className="block text-softBrown mb-1 mr-2" htmlFor="isDeceased">Is Deceased</label>
            <input
              type="checkbox"
              id="isDeceased"
              name="isDeceased"
              checked={formData.isDeceased}
              onChange={handleChange}
              className="mr-2"
            />
          </div>
          {formData.isDeceased && (
            <>
              <div className="mb-4">
                <label className="block text-softBrown mb-1" htmlFor="deathDate">Death Date</label>
                <input
                  type="date"
                  id="deathDate"
                  name="deathDate"
                  value={formData.deathDate}
                  onChange={handleChange}
                  className="w-full border border-softBrown rounded px-2 py-1"
                />
              </div>
              <div className="mb-4">
                <label className="block text-softBrown mb-1" htmlFor="deathPlace">Death Place</label>
                <input
                  type="text"
                  id="deathPlace"
                  name="deathPlace"
                  value={formData.deathPlace}
                  onChange={handleChange}
                  className="w-full border border-softBrown rounded px-2 py-1"
                />
              </div>
            </>
          )}
          <div className="mb-4">
            <label className="block text-softBrown mb-1" htmlFor="occupation">Occupation</label>
            <input
              type="text"
              id="occupation"
              name="occupation"
              value={formData.occupation}
              onChange={handleChange}
              className="w-full border border-softBrown rounded px-2 py-1"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Photo</label>
            {formData.photo ? (
              <div className="relative w-24 h-24 mb-2">
                <img src={formData.photo} alt="Uploaded" className="w-24 h-24 rounded-full" />
                <button
                  type="button"
                  onClick={handleClearPhoto}
                  className="absolute top-0 right-0 bg-red-500 text-white p-2 rounded-full"
                >
                  <FaTrash />
                </button>
              </div>
            ) : (
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="mt-1 block w-full"
              />
            )}
          </div>
          <div className="mb-4">
            <label className="block text-softBrown mb-1" htmlFor="notes">Notes</label>
            <textarea
              id="notes"
              name="notes"
              value={formData.notes}
              onChange={handleChange}
              className="w-full border border-softBrown rounded px-2 py-1"
            />
          </div>
          <div className="flex justify-end mt-4">
            <button
              type="button"
              onClick={onClose}
              className="bg-softApricot text-softBrown font-heading py-2 px-4 mr-2 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-warmPeach text-softBrown font-heading py-2 px-4 rounded"
              disabled={loading}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPersonForm;
