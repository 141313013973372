// src/components/LoadingSpinner.js

import React from 'react';
import { FaSpinner } from 'react-icons/fa';

const LoadingSpinner = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-warmIvory">
      <FaSpinner className="text-softBrown animate-spin" size={48} />
    </div>
  );
};

export default LoadingSpinner;
