// src/context/PeopleContext.js

import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, onSnapshot } from 'firebase/firestore';
import { auth, db } from '../firebase';

const PeopleContext = createContext();

const peopleReducer = (state, action) => {
  switch (action.type) {
    case 'SET_PEOPLE':
      return { ...state, people: action.people, loading: false };
    case 'SET_LOADING':
      return { ...state, loading: action.loading };
    case 'SET_USER':
      return { ...state, user: action.user, authChecked: true };
    case 'SET_AUTH_CHECKED':
      return { ...state, authChecked: action.authChecked };
    default:
      return state;
  }
};

export const PeopleProvider = ({ children }) => {
  const initialState = {
    people: JSON.parse(localStorage.getItem('people')) || [],
    user: null,
    loading: true,
    authChecked: false,
  };

  const [state, dispatch] = useReducer(peopleReducer, initialState);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      dispatch({ type: 'SET_USER', user: user || null });
      if (user) {
        const peopleRef = collection(db, 'users', user.uid, 'people');
        onSnapshot(peopleRef, (snapshot) => {
          const peopleData = [];
          snapshot.forEach((doc) => {
            peopleData.push({ id: doc.id, ...doc.data() });
          });
          localStorage.setItem('people', JSON.stringify(peopleData));
          dispatch({ type: 'SET_PEOPLE', people: peopleData });
        });
      } else {
        dispatch({ type: 'SET_PEOPLE', people: [] });
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (auth.currentUser === null && !state.authChecked) {
      dispatch({ type: 'SET_AUTH_CHECKED', authChecked: true });
    }
  }, [state.authChecked]);

  return (
    <PeopleContext.Provider value={{ state, dispatch }}>
      {children}
    </PeopleContext.Provider>
  );
};

export const usePeople = () => useContext(PeopleContext);
