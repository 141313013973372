// src/components/PersonCard.js

import React from 'react';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';

const PersonCard = ({ person, onEdit, onDelete, onAddRelation, disableDelete }) => {
  return (
    <div className="group relative m-4 p-6 bg-white rounded-xl shadow-lg text-center hover:shadow-2xl transition-shadow">
      {person.photo ? (
        <img
          src={person.photo}
          alt={person.name}
          className="w-24 h-24 rounded-full mx-auto mb-4"
        />
      ) : (
        <div className="w-24 h-24 bg-gray-200 rounded-full mx-auto mb-4 flex items-center justify-center text-2xl">
          {person.name[0]}
        </div>
      )}
      <div className="absolute top-2 right-2 flex space-x-2 opacity-0 group-hover:opacity-100 transition-opacity">
        <button onClick={onEdit} className="bg-softApricot text-softBrown p-2 rounded-full">
          <FaEdit />
        </button>
        {!disableDelete && (
          <button onClick={onDelete} className="bg-softApricot text-softBrown p-2 rounded-full">
            <FaTrash />
          </button>
        )}
      </div>
      <button onClick={onAddRelation} className="absolute bottom-2 right-2 bg-softApricot text-softBrown p-2 rounded-full">
        <FaPlus />
      </button>
    </div>
  );
};

export default PersonCard;
