// src/pages/Homepage.js

import React, { useEffect, useState, useCallback } from 'react';
import { signOut } from 'firebase/auth';
import { collection, addDoc, updateDoc, deleteDoc, getDocs, doc, writeBatch } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { usePeople } from '../context/PeopleContext';
import AddPersonForm from '../components/AddPersonForm';
import EditPersonForm from '../components/EditPersonForm';
import PersonCard from '../components/PersonCard';
import ConfirmationDialog from '../components/ConfirmationDialog';
import Toast from '../components/Toast';
import { FaFileExport, FaFileImport, FaPlus } from 'react-icons/fa';
import ClickOutsideWrapper from '../components/ClickOutsideWrapper';

const Homepage = () => {
  const { state, dispatch } = usePeople();
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [currentPerson, setCurrentPerson] = useState(null);
  const [relationOptions, setRelationOptions] = useState(null);
  const [formTitle, setFormTitle] = useState('Add Self');
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [personToDelete, setPersonToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({ message: '', type: '' });

  const fetchPeople = useCallback(async (userId) => {
    try {
      const peopleRef = collection(db, 'users', userId, 'people');
      const snapshot = await getDocs(peopleRef);
      const peopleData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      dispatch({ type: 'SET_PEOPLE', people: peopleData });
    } catch (error) {
      console.error("Error fetching people data:", error);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchPeople(state.user.uid);
  }, [fetchPeople, state.user.uid]);

  const handleFormSubmit = async (formData) => {
    const relatedTo = currentPerson ? currentPerson.personId : null;
    setShowAddForm(false);
    setLoading(true);
    setToast({ message: 'Person added successfully!', type: 'success' });
    try {
      await addDoc(collection(db, 'users', state.user.uid, 'people'), {
        ...formData,
        relatedTo,
        createdAt: new Date(),
      });
      fetchPeople(state.user.uid);
    } catch (error) {
      console.error('Error adding document: ', error);
      setToast({ message: 'Failed to add person.', type: 'error' });
    }
    setLoading(false);
  };

  const handleEditSubmit = async (id, formData) => {
    setShowEditForm(false);
    setCurrentPerson(null);
    setLoading(true);
    setToast({ message: 'Person updated successfully!', type: 'success' });
    try {
      await updateDoc(doc(db, 'users', state.user.uid, 'people', id), {
        ...formData,
        updatedAt: new Date(),
      });
      fetchPeople(state.user.uid);
    } catch (error) {
      console.error('Error updating document: ', error);
      setToast({ message: 'Failed to update person.', type: 'error' });
    }
    setLoading(false);
  };

  const handleDelete = (person) => {
    setPersonToDelete(person);
    setShowDeleteConfirmation(true);
  };

  const confirmDelete = async () => {
    const personId = personToDelete.id;
    setShowDeleteConfirmation(false);
    setPersonToDelete(null);
    setLoading(true);
    setToast({ message: 'Person deleted successfully!', type: 'success' });
    try {
      await deleteDoc(doc(db, 'users', state.user.uid, 'people', personId));
      dispatch({ type: 'SET_PEOPLE', people: state.people.filter((person) => person.id !== personId) });
    } catch (error) {
      console.error('Error deleting document: ', error);
      setToast({ message: 'Failed to delete person.', type: 'error' });
    }
    setLoading(false);
  };

  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
    setPersonToDelete(null);
  };

  const handleAddSelf = () => {
    setRelationOptions(null);
    setFormTitle('Add Self');
    setShowAddForm(true);
  };

  const handleExportData = async () => {
    const userId = state.user.uid;
    const peopleRef = collection(db, 'users', userId, 'people');
    const snapshot = await getDocs(peopleRef);
    const data = [];
    snapshot.forEach((doc) => {
      data.push(doc.data());
    });

    const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'backup.json';
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleImportData = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const userId = state.user.uid;

    const reader = new FileReader();
    reader.onload = async (e) => {
      const content = e.target.result;
      try {
        const data = JSON.parse(content);

        const peopleRef = collection(db, 'users', userId, 'people');
        const snapshot = await getDocs(peopleRef);
        const batch = writeBatch(db);
        snapshot.docs.forEach((doc) => {
          batch.delete(doc.ref);
        });
        await batch.commit();

        // Import new data
        for (const person of data) {
          await addDoc(peopleRef, person);
        }

        // Fetch the updated data
        fetchPeople(userId);
        setToast({ message: 'Data imported successfully!', type: 'success' });
      } catch (error) {
        console.error('Error importing data: ', error);
        setToast({ message: 'Failed to import data.', type: 'error' });
      }
    };
    reader.readAsText(file);
  };

  const handleAddRelation = (person) => {
    setFormTitle(`Add ${person.name}'s Relative`);
    setRelationOptions(['Parent', 'Sibling', 'Child', 'Spouse']);
    setCurrentPerson(person);
    setShowAddForm(true);
  };

  const handleEdit = (person) => {
    if (person.relation) {
      setFormTitle(`Edit ${person.name}`);
      setRelationOptions(['Parent', 'Sibling', 'Child', 'Spouse']);
    } else {
      setFormTitle('Edit Self');
      setRelationOptions(null);
    }
    setCurrentPerson(person);
    setShowEditForm(true);
  };

  const handleLogout = () => {
    setShowLogoutConfirmation(true);
  };

  const confirmLogout = () => {
    signOut(auth).catch((error) => {
      console.error('Error logging out: ', error);
    });
    setShowLogoutConfirmation(false);
  };

  const cancelLogout = () => {
    setShowLogoutConfirmation(false);
  };

  // Determine if the self card should have the delete button disabled
  const disableSelfDelete = state.people.length > 1;

  return (
    <div className="App">
      <div className="flex flex-col justify-between h-screen bg-warmIvory">
        <div className="flex justify-end p-4">
          <button
            onClick={handleExportData}
            className="bg-softApricot text-softBrown font-heading py-2 px-4 mx-2 rounded cursor-pointer flex items-center"
          >
            <FaFileExport className="mr-2" /> Export Data
          </button>
          <button
            onClick={handleLogout}
            className="bg-softApricot text-softBrown font-heading py-2 px-4 rounded"
          >
            Logout
          </button>
        </div>
        {state.people.length === 0 ? (
          <div className="flex flex-col justify-center items-center flex-grow">
            <div className="flex space-x-4 mt-4">
              <button
                onClick={handleAddSelf}
                className="bg-softApricot text-softBrown font-heading py-2 px-4 rounded cursor-pointer flex items-center"
              >
                <FaPlus className="mr-2" /> Add Self
              </button>
              <input type="file" onChange={handleImportData} className="hidden" id="import-file" />
              <label
                htmlFor="import-file"
                className="bg-softApricot text-softBrown font-heading py-2 px-4 rounded cursor-pointer flex items-center"
              >
                <FaFileImport className="mr-2" /> Import Data
              </label>
            </div>
          </div>
        ) : (
          <div className="flex flex-wrap justify-center items-center flex-grow">
            {state.people.map((person, index) => (
              <div key={person.id} className="flex flex-col items-center m-4">
                <PersonCard
                  person={person}
                  onEdit={() => handleEdit(person)}
                  onDelete={() => handleDelete(person)}
                  onAddRelation={() => handleAddRelation(person)}
                  disableDelete={!person.relation && disableSelfDelete} // Disable delete if this is the self card and other cards exist
                />
                <div className="mt-2 text-center">
                  <div className="text-lg font-bold">{person.name}</div>
                  <div className="text-sm text-gray-500">
                    {!person.relation ? 'Self' : person.relation}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {showAddForm && (
        <ClickOutsideWrapper onClickOutside={() => setShowAddForm(false)}>
          <AddPersonForm
            onSubmit={(formData) => {
              handleFormSubmit({ ...formData, relatedTo: currentPerson ? currentPerson.personId : null });
            }}
            onClose={() => setShowAddForm(false)}
            relationOptions={relationOptions}
            formTitle={formTitle}
            loading={loading}
          />
        </ClickOutsideWrapper>
      )}
      {showEditForm && currentPerson && (
        <ClickOutsideWrapper onClickOutside={() => setShowEditForm(false)}>
          <EditPersonForm
            person={currentPerson}
            onSubmit={handleEditSubmit}
            onClose={() => setShowEditForm(false)}
            relationOptions={relationOptions}
            formTitle={formTitle}
            loading={loading}
          />
        </ClickOutsideWrapper>
      )}
      {showLogoutConfirmation && (
        <ClickOutsideWrapper onClickOutside={() => setShowLogoutConfirmation(false)}>
          <ConfirmationDialog
            isOpen={showLogoutConfirmation}
            title="Confirm Logout"
            message="Are you sure you want to logout?"
            onConfirm={confirmLogout}
            onCancel={cancelLogout}
          />
        </ClickOutsideWrapper>
      )}
      {showDeleteConfirmation && (
        <ClickOutsideWrapper onClickOutside={() => setShowDeleteConfirmation(false)}>
          <ConfirmationDialog
            isOpen={showDeleteConfirmation}
            title="Confirm Delete"
            message="Are you sure you want to delete this person?"
            onConfirm={confirmDelete}
            onCancel={cancelDelete}
          />
        </ClickOutsideWrapper>
      )}
      {toast.message && (
        <Toast message={toast.message} type={toast.type} onClose={() => setToast({ message: '', type: '' })} />
      )}
    </div>
  );
};

export default Homepage;
