// src/pages/LandingPage.js

import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUserPlus, FaTree, FaFileExport, FaLock } from 'react-icons/fa';
import { AuthContext } from '../context/AuthContext';

const LandingPage = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (currentUser) {
      navigate('/');
    }
  }, [currentUser, navigate]);

  const handleGetStarted = () => {
    navigate('/login');
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center bg-warmIvory space-y-8 px-4">
      <h1 className="text-5xl font-heading text-softBrown mb-8 text-center">Welcome to MyFamm</h1>
      <p className="text-lg text-center text-softBrown max-w-2xl">
        Discover your family heritage, connect with relatives, and preserve your family history with our easy-to-use Family Tree app.
        Whether you're building your tree from scratch or adding to an existing one, our app provides all the tools you need to explore
        your ancestry and share your story with loved ones.
      </p>
      <div className="text-center mt-8 text-softBrown">
        <h2 className="text-2xl font-heading mb-4">Features</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 text-lg">
          <div className="group relative p-6 bg-white rounded-xl shadow-lg text-center hover:shadow-2xl transition-shadow">
            <FaUserPlus className="w-12 h-12 text-softBrown mx-auto mb-4" />
            <p className="text-sm text-softBrown">Add and edit family members</p>
          </div>
          <div className="group relative p-6 bg-white rounded-xl shadow-lg text-center hover:shadow-2xl transition-shadow">
            <FaTree className="w-12 h-12 text-softBrown mx-auto mb-4" />
            <p className="text-sm text-softBrown">Visualize your family tree</p>
          </div>
          <div className="group relative p-6 bg-white rounded-xl shadow-lg text-center hover:shadow-2xl transition-shadow">
            <FaFileExport className="w-12 h-12 text-softBrown mx-auto mb-4" />
            <p className="text-sm text-softBrown">Export and import family data</p>
          </div>
          <div className="group relative p-6 bg-white rounded-xl shadow-lg text-center hover:shadow-2xl transition-shadow">
            <FaLock className="w-12 h-12 text-softBrown mx-auto mb-4" />
            <p className="text-sm text-softBrown">Secure and private</p>
          </div>
        </div>
      </div>
      <button 
        onClick={handleGetStarted} 
        className="w-full max-w-xs mt-8 bg-warmPeach text-softBrown font-heading py-2 px-4 rounded"
      >
        Get Started
      </button>
    </div>
  );
};

export default LandingPage;
