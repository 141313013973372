// src/pages/Signup.js

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, sendEmailVerification, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../firebase';
import { FaGoogle, FaEye, FaEyeSlash } from 'react-icons/fa';

const Signup = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signupError, setSignupError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await sendEmailVerification(user);
      setSuccessMessage('A verification email has been sent. Please check your inbox.');
    } catch (error) {
      setSignupError(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      if (result.user.emailVerified) {
        navigate('/');
      } else {
        setSignupError('Please verify your email before logging in.');
        await auth.signOut();
      }
    } catch (error) {
      console.error("Google sign in error:", error);
      setSignupError(getErrorMessage(error.code));
    }
  };

  const getErrorMessage = (errorCode) => {
    switch (errorCode) {
      case 'auth/user-disabled':
        return 'This account has been disabled. Please contact support for help.';
      case 'auth/user-not-found':
        return 'No account found with this email. Please sign up.';
      case 'auth/invalid-credential':
        return 'Incorrect email or password. Please try again.';
      case 'auth/email-already-in-use':
        return 'Email already in use. Please log in or use a different email.';
      case 'auth/weak-password':
        return 'Weak password. Please use a stronger password.';
      default:
        return 'An unexpected error occurred. Please try again later.';
    }
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center bg-warmIvory space-y-8 px-4">
      <h1 className="text-4xl font-heading text-softBrown mb-8">Sign Up for MyFamm</h1>
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md space-y-4">
        <form onSubmit={handleSignUp}>
          <input
            type="email"
            placeholder="Enter your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="border-2 border-softBrown rounded w-full px-4 py-2 text-softBrown"
            required
          />
          <div className="relative mt-2">
            <input
              type={isPasswordVisible ? 'text' : 'password'}
              placeholder="Enter your Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="border-2 border-softBrown rounded w-full px-4 py-2 text-softBrown"
              required
            />
            <button
              type="button"
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              className="absolute right-4 top-1/2 transform -translate-y-1/2 text-softBrown"
            >
              {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <button type="submit" className="w-full mt-2 bg-warmPeach text-softBrown font-heading py-2 px-4 rounded">
            Sign Up
          </button>
          <div className="text-center mt-4 cursor-pointer text-softBrown">
            <Link to="/login">Already have an account? Log in</Link>
          </div>
        </form>
        <div className="relative flex py-5 items-center">
          <div className="flex-grow border-t border-softBrown"></div>
          <span className="flex-shrink mx-4 text-softBrown">OR</span>
          <div className="flex-grow border-t border-softBrown"></div>
        </div>
        <div>
          <button
            onClick={handleGoogleSignIn}
            className="w-full bg-softApricot text-softBrown font-heading py-2 px-4 rounded flex items-center justify-center"
          >
            <FaGoogle className="mr-2" /> Sign in with Google
          </button>
        </div>
        {signupError && <div className="text-red-500 mt-2">{signupError}</div>}
        {successMessage && <div className="text-green-500 mt-2">{successMessage}</div>}
      </div>
    </div>
  );
};

export default Signup;
