// src/components/Toast.js

import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const Toast = ({ message, type, onClose }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const showTimer = setTimeout(() => {
      setVisible(true);
    }, 100); // Short delay to trigger transition

    const hideTimer = setTimeout(() => {
      setVisible(false);
      const removeTimer = setTimeout(() => {
        onClose();
      }, 500); // This matches the transition duration

      return () => clearTimeout(removeTimer);
    }, 2000); // Visible duration of the toast

    return () => {
      clearTimeout(showTimer);
      clearTimeout(hideTimer);
    };
  }, [onClose]);

  const toastStyles = {
    success: 'bg-green-500 text-white',
    error: 'bg-red-500 text-white',
  };

  const iconStyles = {
    success: <FaCheckCircle className="mr-2" />,
    error: <FaTimesCircle className="mr-2" />,
  };

  return (
    <div
      className={`fixed bottom-4 right-4 flex items-center p-4 rounded shadow-lg transition-opacity duration-500 font-body ${
        toastStyles[type]
      } ${visible ? 'opacity-100' : 'opacity-0'}`}
    >
      {iconStyles[type]}
      {message}
    </div>
  );
};

export default Toast;
