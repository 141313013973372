// src/firebase.js

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyBfdSJhUJsWQXAA33fEeVbZV0vOf7lscdk",
  // authDomain: "family-tree-app-8f5f2.firebaseapp.com",
  authDomain: "myfamm.com",
  projectId: "family-tree-app-8f5f2",
  storageBucket: "family-tree-app-8f5f2.appspot.com",
  messagingSenderId: "140479512804",
  appId: "1:140479512804:web:730ae3bddce5ce7727ecf1",
  measurementId: "G-SZQ5E3S52C"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
