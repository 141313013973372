// src/pages/ResetPassword.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { sendPasswordResetEmail, confirmPasswordReset } from 'firebase/auth';
import { auth } from '../firebase';

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [resetError, setResetError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [oobCode, setOobCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleSendResetEmail = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setSuccessMessage('A password reset email has been sent. Please check your inbox.');
    } catch (error) {
      setResetError(error.message);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
      setSuccessMessage('Password has been reset successfully.');
      navigate('/login');
    } catch (error) {
      setResetError(error.message);
    }
  };

  useEffect(() => {
    const checkForOobCode = () => {
      const urlParams = new URLSearchParams(location.search);
      const code = urlParams.get('oobCode');
      if (code) {
        setOobCode(code);
      }
    };

    checkForOobCode();
  }, [location.search]);

  return (
    <div className="h-screen flex flex-col items-center justify-center bg-warmIvory space-y-8 px-4">
      <h1 className="text-4xl font-heading text-softBrown mb-8">Reset Password</h1>
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md space-y-4">
        {resetError && <div className="text-red-500">{resetError}</div>}
        {successMessage && <div className="text-green-500">{successMessage}</div>}
        {!oobCode ? (
          <form onSubmit={handleSendResetEmail} className="space-y-4">
            <input
              type="email"
              placeholder="Enter your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="border-2 border-softBrown rounded w-full px-4 py-2 text-softBrown"
              required
            />
            <button type="submit" className="w-full bg-warmPeach text-softBrown font-heading py-2 px-4 rounded">
              Send Password Reset Email
            </button>
          </form>
        ) : (
          <form onSubmit={handleResetPassword} className="space-y-4">
            <div className="relative">
              <input
                type={isPasswordVisible ? 'text' : 'password'}
                placeholder="Enter your new Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="border-2 border-softBrown rounded w-full px-4 py-2 text-softBrown"
                required
              />
              <button
                type="button"
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                className="absolute right-4 top-1/2 transform -translate-y-1/2 text-softBrown"
              >
                {isPasswordVisible ? 'Hide' : 'Show'}
              </button>
            </div>
            <button type="submit" className="w-full bg-warmPeach text-softBrown font-heading py-2 px-4 rounded">
              Reset Password
            </button>
          </form>
        )}
        <button type="button" onClick={() => navigate('/login')} className="w-full bg-warmPeach text-softBrown font-heading py-2 px-4 rounded">
          Back to Login
        </button>
      </div>
    </div>
  );
};

export default ResetPassword;
