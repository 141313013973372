// src/components/ClickOutsideWrapper.js

import React, { useRef } from 'react';
import useClickOutsideAndEscape from '../hooks/useClickOutsideAndEscape';

const ClickOutsideWrapper = ({ children, onClickOutside }) => {
  const wrapperRef = useRef(null);
  useClickOutsideAndEscape(wrapperRef, onClickOutside);

  return <div ref={wrapperRef}>{children}</div>;
};

export default ClickOutsideWrapper;


